import React, { lazy, Suspense } from 'react';
import { UserAuthContextProvider } from './context/UserAuthContext';
// import LanguageSelector from './components/LanguageSelector/LanguageSelector';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Body from './Body';
import './i18Next';
import { Provider } from 'react-redux';
import appStore from './utilis/appStore';
import ProtectedRoute from './components/Chatbot/ProtectedRoute';
// import useGoogleAnalytics from './context/useGoogleAnalytics';
// import { useTranslation } from 'react-i18next';
const Experts = lazy(() => import('./components/Experts/Experts'));
const AboutExpert = lazy(() => import('./components/Experts/AboutExpert'));
const ExpertsPayment = lazy(() => import('./components/Experts/ExpertsPayment'));
const ExpertsPaymentStatus = lazy(() => import('./components/Experts/ExpertsPaymentStatus'));
const ExpertPaymentSuccess = lazy(() => import('./components/Experts/ExpertPaymentSuccess'));
const Home = lazy(() => import('./components/Home/Home'));
const Blogs = lazy(() => import('./components/Blog/Blogs'));
const FullBlog = lazy(() => import('./components/Blog/FullBlog'));
const AffiliatePage = lazy(() => import('./components/SubscriptionPayment/AffiliatePage'));
const SubscriptionPay = lazy(() => import('./components/SubscriptionPayment/SubscriptionPay'));
const SubscriptionSuccess = lazy(() => import('./components/SubscriptionPayment/SubscriptionSuccess'));
const Paymentform = lazy(() => import('./components/Payment/Paymentform'));
const PaymentStatus = lazy(() => import('./components/Payment/PaymentStatus'));
const PaymentSuccess = lazy(() => import('./components/Payment/PaymentSuccess'));
const MandateSuccess = lazy(() => import('./components/Payment/MandateSuccess'));
const PaymentFail = lazy(() => import('./components/Payment/PaymentFail'));
const SubscriptionTrial = lazy(() => import('./components/SubscriptionTrial/SubscriptionTrial'));
const SubscriptionTrailSuccess = lazy(() => import('./components/SubscriptionTrial/SubscriptionTrailSuccess'));
const TrialChat = lazy(() => import('./components/Chatbot/TrialChat'));
const UserChat = lazy(() => import('./components/Chatbot/UserChat'));
const Login = lazy(() => import('./components/Chatbot/Login'));
const Blocker = lazy(() => import('./components/Chatbot/Blocker'));
const Contact = lazy(() => import('./components/Privacypolicy/Contact'));
const EventPayment = lazy(() => import('./components/EventPayment/EventPayment'));
const EventPaymentStatus = lazy(() => import('./components/EventPayment/EventPaymentStatus'));
const EventPaymentSuccess  = lazy(() => import('./components/EventPayment/EventPaymentSuccess'));
const RecordingPayment2999 = lazy(() => import('./components/RecordingPayment/RecordingPayment2999'));
const RecordingPayment4999 = lazy(() => import('./components/RecordingPayment/RecordingPayment4999'));

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Body />,
    children: [
      {
        path: "/",
        element: <Suspense fallback={<></>}><Home /></Suspense>,
      },
      {
        path: "/blogs",
        element: <Suspense fallback={<></>}><Blogs /></Suspense>,
      },
      {
        path: "/blog/:blogname",
        element: <Suspense fallback={<></>}><FullBlog /></Suspense>
      },
      {
        path: "/experts",
        element: <Suspense fallback={<></>}><Experts /></Suspense>
      },
      {
        path: "/expert/:id",
        element: <Suspense fallback={<></>}><AboutExpert /></Suspense>
      },
      {
        path: "/experts/payment",
        element: <Suspense fallback={<></>}><ExpertsPayment /></Suspense>
      },
      {
        path: "/expert/status/:tnx_id",
        element: <Suspense fallback={<></>}><ExpertsPaymentStatus /></Suspense>
      },
      {
        path: "/expert/success",
        element: <Suspense fallback={<></>}><ExpertPaymentSuccess /></Suspense>
      },
      {
        path: "/subscription_pay/:affiliateId",
        element: <Suspense fallback={<></>}><AffiliatePage /></Suspense>
      },
      {
        path: "/payment",
        element: <Suspense fallback={<></>}><Paymentform /></Suspense>
      },
      {
        path: "/subscription_pay",
        element: <Suspense fallback={<></>}><SubscriptionPay /></Suspense>
      },
      {
        path: "/subscription_pay/success",
        element: <Suspense fallback={<></>}><SubscriptionSuccess /></Suspense>
      },
      {
        path: "/subscription/trial",
        element: <Suspense fallback={<></>}><SubscriptionTrial /></Suspense>
      },
      {
        path: "/subscription_trial/success",
        element: <Suspense fallback={<></>}><SubscriptionTrailSuccess /></Suspense>
      },
      {
        path: "/status/:tnx_id",
        element: <Suspense fallback={<></>}><PaymentStatus /></Suspense>
      },
      {
        path: "/success",
        element: <Suspense fallback={<></>}><PaymentSuccess /></Suspense>
      },
      {
        path: "/mandate_setup/success",
        element: <Suspense fallback={<></>}><MandateSuccess /></Suspense>
      },
      {
        path: "/failure",
        element: <Suspense fallback={<></>}><PaymentFail /></Suspense>
      },
      {
        path: "/chat/trial",
        element: <Suspense fallback={<></>}><TrialChat /></Suspense>
      },
      {
        path: "/chat/user",
        element: <Suspense fallback={<></>}><ProtectedRoute><UserChat /></ ProtectedRoute></Suspense>
      },
      {
        path: "/login",
        element: <Suspense fallback={<></>}><Login /></Suspense>
      },
      {
        path: "/block",
        element: <Suspense fallback={<></>}><Blocker /></Suspense>
      },
      {
        path: "/privacy",
        element: <Suspense fallback={<></>}><Contact /></Suspense>
      },
      {
        path: "/event/payment",
        element: <Suspense fallback={<></>}><EventPayment /></Suspense>
      },
      {
        path: "/event/status/:tnx_id",
        element: <Suspense fallback={<></>}><EventPaymentStatus /></Suspense>
      },
      {
        path: "/event/success",
        element: <Suspense fallback={<></>}><EventPaymentSuccess /></Suspense>
      },
      {
        path: "/recording/payment/1",
        element: <Suspense fallback={<></>}><RecordingPayment2999 /></Suspense>
      },
      {
        path: "/recording/payment/2",
        element: <Suspense fallback={<></>}><RecordingPayment4999 /></Suspense>
      },
    ],
  },
]);

const App = () => {
  // const { i18n } = useTranslation();

  // const setter = localStorage.getItem("lang");

  // useEffect(() => {
  //   if(setter === "en") {
  //     i18n.changeLanguage("en");
  //   } else if ( setter === "hi") {
  //     i18n.changeLanguage("hi");
  //   }
  // }, []);
  // useGoogleAnalytics();

  return (
    <Provider store={appStore}>
      <UserAuthContextProvider>
        <div className="w-screen">
          {/* {setter &&  */}
          <RouterProvider router={appRouter} />
          {/* {!setter && <LanguageSelector />} */}
        </div>
      </UserAuthContextProvider>
    </Provider>
  );
} 

export default App;